import { FileSymlink } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  BulkUpdateMeasurementStateInput,
  BulkUpdateMeasurementStatePayload,
  MeasurementStatesEnum
} from 'src/__apolloGenerated__/graphql';
import { Button } from 'src/components/shad-base/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import { ROUTES } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';

import useBackendMutation from 'src/hooks/useBackendMutation';
import { BULK_UPDATE_MEASUREMENT_STATE } from 'src/hooks/useBackendMutation/mutations';
import { ToReviewMeasurementType } from 'src/layouts/Layout/SidebarNav';
import { getListItemKey } from 'src/utils/format';

function MeasurementRow({
  option,
  toReviewMeasurements
}: {
  option: { key: string; label: string };
  toReviewMeasurements: ToReviewMeasurementType[];
}) {
  const router = useRouter();

  const {
    selectedStartDate,
    selectedEndDate,
    selectedFilters,
    setActiveNode
  } = useMeasureStore((store: MeasureStoreType) => ({
    selectedStartDate: store.selectedStartDate,
    selectedEndDate: store.selectedEndDate,
    selectedFilters: store.selectedFilters,
    setActiveNode: store.setActiveNode
  }));

  const { panelMinimized, setPanelMinimized } = useAppStore(
    (store) => ({
      panelMinimized: store.panelMinimized,
      setPanelMinimized: store.setPanelMinimized
    })
  );

  const { mutate: setMeasurementState, loading } = useBackendMutation<
    {
      input: BulkUpdateMeasurementStateInput;
      startDate: Date;
      endDate: Date;
      states: MeasurementStatesEnum[];
    },
    BulkUpdateMeasurementStatePayload
  >({
    mutation: BULK_UPDATE_MEASUREMENT_STATE
  });

  return (
    <div className="mb-sm w-full">
      <div className="flex max-w-full flex-nowrap items-center justify-between overflow-ellipsis">
        {/* Name and dot */}
        <div className="max-w-2/3 mr-sm">
          <div className="overflow-ellipsis">
            <p className="body2 text-nowrap">{option.label}</p>
          </div>
        </div>

        {/* Buttons */}
        <div>
          <div className="flex flex-nowrap">
            <div>
              <Button
                size="sm"
                variant="outline"
                loading={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  setMeasurementState({
                    variables: {
                      input: {
                        measurementIdentifiers: [option.key],
                        newState: MeasurementStatesEnum.Recorded
                      },
                      startDate: selectedStartDate,
                      endDate: selectedEndDate,
                      states: selectedFilters
                    },
                    refetchQueries: [
                      'TreeHeadersToReviewAggregateMeasurementsQuery'
                    ]
                  });
                }}
              >
                Record
              </Button>
            </div>
            <div className="ml-sm">
              <Button
                variant="outline"
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();

                  //find measurement in toReviewMeasurements
                  const measurement = toReviewMeasurements.find(
                    (measurement) =>
                      measurement.identifier === option.key
                  );
                  if (router.pathname !== ROUTES.INVENTORY.MEASURE) {
                    router.push(ROUTES.INVENTORY.MEASURE);
                  }
                  setActiveNode({
                    nodeId: option.key,
                    type: 'MEASUREMENT',
                    path: measurement?.branch?.path
                  });

                  if (panelMinimized) {
                    setPanelMinimized(false);
                  }
                }}
              >
                <FileSymlink className="h-icon w-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ReviewMeasurementsPopper({
  toReviewMeasurements
}: {
  toReviewMeasurements: ToReviewMeasurementType[];
}) {
  const [open, setOpen] = useState(false);

  const { selectedStartDate, selectedEndDate, selectedFilters } =
    useMeasureStore((store: MeasureStoreType) => ({
      selectedStartDate: store.selectedStartDate,
      selectedEndDate: store.selectedEndDate,
      selectedFilters: store.selectedFilters
    }));

  const measurementOptions = toReviewMeasurements?.map(
    (measurement) => {
      return {
        key: measurement?.identifier,
        label: measurement?.name
      };
    }
  );

  const { mutate: bulkSetState, loading: updatingState } =
    useBackendMutation<
      {
        input: BulkUpdateMeasurementStateInput;
        startDate: Date;
        endDate: Date;
        states: MeasurementStatesEnum[];
      },
      BulkUpdateMeasurementStatePayload
    >({
      mutation: BULK_UPDATE_MEASUREMENT_STATE
    });

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger className="w-full" asChild>
        <div className="flex w-full flex-nowrap items-center justify-between">
          <div>
            <p>Needs Review</p>
          </div>
          {toReviewMeasurements?.length > 0 && (
            <div className="flex items-center">
              <div className="flex h-3 w-3 rounded-full bg-warning" />
              <div className="ml-sm">
                <p>{toReviewMeasurements?.length}</p>
              </div>
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="max-h-[600px] overflow-y-scroll">
        <div className="flex flex-col flex-nowrap">
          <p className="mb-md border-b pb-sm">
            These measurements are still in need of review.
          </p>
          {measurementOptions?.map((option, optionIdx) => {
            return (
              <MeasurementRow
                key={getListItemKey(optionIdx)}
                option={option}
                toReviewMeasurements={toReviewMeasurements}
              />
            );
          })}

          <div className="mt-lg w-full">
            <Button
              size="sm"
              className="w-full"
              loading={updatingState}
              onClick={() => {
                const measurementIdentifiers =
                  toReviewMeasurements.map(
                    (measurement) => measurement.identifier
                  );

                bulkSetState({
                  variables: {
                    input: {
                      measurementIdentifiers: measurementIdentifiers,
                      newState: MeasurementStatesEnum.Recorded
                    },
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    states: selectedFilters
                  },
                  refetchQueries: [
                    'TreeHeadersToReviewAggregateMeasurementsQuery'
                  ]
                });
              }}
            >
              Record All ({toReviewMeasurements?.length})
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
