import { gql, useQuery } from '@apollo/client';
import {
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  CardPaymentMethodObject,
  CreateSubscriptionInput,
  CreateSubscriptionPayload,
  InactivateSubscriptionInput,
  InactivateSubscriptionPayload,
  SubscribeDialogUserQueryQuery,
  SubscriptionFrequenciesEnum,
  SubscriptionPlanTiersEnum,
  UpdateSubscriptionInput,
  UpdateSubscriptionPayload
} from 'src/__apolloGenerated__/graphql';
import Input from 'src/components/core/atoms/Input';
import InvoicePreview from 'src/components/core/organisms/dialogs/SubscribeDialog/InvoicePreview';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';
import {
  RadioGroup,
  RadioGroupItem
} from 'src/components/shad-base/radio-group';
import {
  ROUTES,
  STRIPE_ELEMENTS_APPEARANCE,
  TOKENS
} from 'src/config';

import useSubscriptionStore from 'src/hooks/store/useSubcriptionStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import {
  CREATE_SUBSCRIPTION,
  INACTIVATE_SUBSCRIPTION
} from 'src/hooks/useBackendMutation/mutations';
import useSubscriptionAlert, {
  AlertTypeType
} from 'src/hooks/useSubscriptionAlert';
import { getListItemKey } from 'src/utils/format';
import snackbar from 'src/utils/snackbar';
import PaymentForm from 'src/views/BillingView/PaymentForm';
import PaymentMethodCard from 'src/views/BillingView/PaymentMethodCard';

export type SubscriptionPlanType = {
  tier: SubscriptionPlanTiersEnum;
  name: string;
  pricePerMonth: number;
  pricePerYear: number;
  topFeatures: string[];
};

const stripePromise = loadStripe(TOKENS.STRIPE.PUBLIC_KEY);

function Inner({
  open,
  onOpenChange,
  newPlan,
  initialBillingOption,
  type,
  disableBillingOption,
  refetchBillingViewQuery
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  newPlan: SubscriptionPlanType;
  initialBillingOption?: SubscriptionFrequenciesEnum;
  type: 'upgrade' | 'downgrade' | 'edit-billing';
  disableBillingOption?: SubscriptionFrequenciesEnum;
  refetchBillingViewQuery?: () => void;
}) {
  const router = useRouter();
  const stripe = useStripe();
  const elements = useElements();

  const { activeSubscriptionTier: subscriptionTier } =
    useSubscriptionStore();

  const { data: userData, loading: loadingUserData } =
    useQuery<SubscribeDialogUserQueryQuery>(gql`
      query SubscribeDialogUserQuery {
        user {
          data {
            entity {
              id
              identifier
              billing {
                identifier
                paymentMethods {
                  lastFour
                  expMonth
                  expYear
                  brand
                }
                activePlan {
                  tier
                  frequency
                }
                activeSubscription {
                  identifier
                  isCancelled
                  validUntil
                  pendingActivation
                  pendingCancellation
                }
                failedPayment {
                  plan {
                    tier
                  }
                }
              }
            }
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `);

  const user = userData?.user?.data;
  const paymentMethods =
    user?.entity.billing.paymentMethods?.length > 0
      ? user?.entity?.billing?.paymentMethods[0]
      : null;

  const activePlan = user?.entity?.billing?.activePlan;
  const activeSubscription =
    user?.entity?.billing?.activeSubscription;

  const activeSubscriptionIsPending =
    activeSubscription?.pendingActivation ||
    activeSubscription?.pendingCancellation;

  const paidPlanTiers = [
    SubscriptionPlanTiersEnum.ProPlus,
    SubscriptionPlanTiersEnum.Advanced
  ];
  // User has cancelled a paid plan mid billing cycle, but still has access until the end of the billing cycle
  const subscriptionIsCancelledAndValidUntil =
    activeSubscription?.isCancelled && activeSubscription?.validUntil;

  // User is on a paid plan but has no payment method saved
  const onPaidPlanButNoPaymentMethod =
    !activeSubscriptionIsPending &&
    paidPlanTiers.includes(activePlan?.tier) &&
    !paymentMethods;

  // Stripe webhook returned a failed state
  const failedPayment = user?.entity?.billing?.failedPayment !== null;

  const alert = useSubscriptionAlert({
    failedPayment,
    pendingActivation: activeSubscription?.pendingActivation,
    pendingCancellation: activeSubscription?.pendingCancellation,
    subscriptionIsCancelledAndValidUntil,
    onPaidPlanButNoPaymentMethod,
    activePlanName: activePlan?.tier
  });

  const [billingFrequency, setBillingFrequency] =
    useState<SubscriptionFrequenciesEnum>(
      initialBillingOption || SubscriptionFrequenciesEnum.Year
    );
  const [isPaymentInfoComplete, setIsPaymentInfoComplete] =
    useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [confirmingCardPayment, setConfirmingCardPayment] =
    useState(false);

  // Input values
  const [promotionCode, setPromotionCode] = useState({
    editValue: null,
    submittedValue: null
  });
  const [invalidPromotionCode, setInvalidPromotionCode] =
    useState(false);
  const [postalCode, setPostalCode] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    if (initialBillingOption) {
      setBillingFrequency(initialBillingOption);
    }
  }, [initialBillingOption]);

  // Reset state when dialog is closed
  useEffect(() => {
    if (!open) {
      setBillingFrequency(
        initialBillingOption || SubscriptionFrequenciesEnum.Year
      );
      setIsPaymentInfoComplete(false);
      setSubscriptionError(null);
      setCountryCode(null);
      setPostalCode(null);
      setPromotionCode({
        editValue: null,
        submittedValue: null
      });
    }
  }, [open]);

  const handleStripeError = (message: string) => {
    // inactivate the pending subscription with unusable payment info
    inactivateSubscription({
      variables: {
        input: {
          exterminate: true
        }
      }
    });
    setSubscriptionError(message);
  };

  // Confirm the payment via Stripe
  const confirmPayment = async (clientSecret) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleStripeError('An error occurred. Please try again.');
      return;
    }

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              postal_code: postalCode,
              country: countryCode
            }
          }
        },
        return_url: window.location.href
      }
    });

    if (error) {
      handleStripeError(error.message);
      return;
    } else {
      refetchBillingViewQuery();
      setConfirmingCardPayment(false);
      onOpenChange(false);
    }
  };

  const {
    mutate: createSubscription,
    loading: creatingSubscription
  } = useBackendMutation<
    { input: CreateSubscriptionInput },
    CreateSubscriptionPayload
  >({
    mutation: CREATE_SUBSCRIPTION,
    callbacks: {
      onSuccess: async (data) => {
        if (!paymentMethods) {
          // Confirm payment details via Stripe
          const clientSecret = data.transactionId;
          if (clientSecret) {
            await confirmPayment(clientSecret);
          } else {
            handleStripeError('An error occurred. Please try again.');
          }
        } else {
          // No need to confirm stripe payment if there is an existing payment method
          snackbar.success('Subscription activated.');
          refetchBillingViewQuery();
          onOpenChange(false);
          setConfirmingCardPayment(false);
        }
      },
      onError: () => {
        setConfirmingCardPayment(false);
        setSubscriptionError('An error occurred. Please try again.');
      }
    }
  });

  const {
    mutate: updateSubscription,
    loading: updatingSubscription
  } = useBackendMutation<
    { input: UpdateSubscriptionInput },
    UpdateSubscriptionPayload
  >({
    mutation: gql`
      mutation UpdateSubscriptionMutation(
        $input: UpdateSubscriptionInput!
      ) {
        updateSubscription(input: $input) {
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `,
    callbacks: {
      onSuccess: () => {
        snackbar.success('Subscription updated.');
        onOpenChange(false);
      }
    }
  });

  const { mutate: inactivateSubscription } = useBackendMutation<
    { input: InactivateSubscriptionInput },
    InactivateSubscriptionPayload
  >({
    mutation: INACTIVATE_SUBSCRIPTION,
    callbacks: {
      onSuccess: () => {
        setConfirmingCardPayment(false);
      }
    }
  });

  const buttonLoading =
    updatingSubscription ||
    creatingSubscription ||
    confirmingCardPayment;

  const submitPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    if (
      !paymentMethods ||
      subscriptionTier === SubscriptionPlanTiersEnum.Pro
    ) {
      setConfirmingCardPayment(true);
      createSubscription({
        variables: {
          input: {
            planFrequency: billingFrequency,
            planTier:
              newPlan?.tier.toUpperCase() as SubscriptionPlanTiersEnum,
            promotionCode:
              promotionCode.submittedValue === ''
                ? undefined
                : promotionCode.submittedValue,
            postalCode,
            countryCode
          }
        }
      });
    } else {
      updateSubscription({
        variables: {
          input: {
            planTier:
              newPlan?.tier.toUpperCase() as SubscriptionPlanTiersEnum,
            planFrequency: billingFrequency
          }
        },
        refetchQueries:
          router.pathname === '/settings/billing'
            ? ['BillingUserQuery', 'LayoutUserQuery']
            : ['LayoutUserQuery']
      });
    }
  };
  const alertMessages: { [key in AlertTypeType]?: string } = {
    'failed-payment':
      'Your payment method has failed. Please visit the billing page to update your payment method and try again.',
    'pending-activation':
      'Another suscription is still pending activation. Please wait a few minutes and try again.',
    'pending-cancellation':
      'Another subscription is still pending cancellation. Please wait a few minutes and try again.',
    'on-paid-plan-but-no-payment-method':
      'We are missing payment information. Please visit the billing page to update your payment method and try again.',

    // Because of the hierarchy of alerts, this will only be shown if the user has cancelled their plan AND removed their payment method.
    'cancelled-and-valid-until':
      'We are missing payment information. Please visit the billing page to update your payment method and try again'
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={'max-w-7xl'}>
        <div className="flex flex-col flex-nowrap p-md">
          {/* Header */}
          <div>
            <h6>
              {type === 'upgrade'
                ? `Upgrade to ${newPlan?.name}`
                : type === 'downgrade'
                  ? `Downgrade to ${newPlan?.name}`
                  : 'Edit billing frequency'}
            </h6>
          </div>
          <div className="mt-sm">
            <p className="text-muted">
              {type === 'upgrade'
                ? `You are about to upgrade your plan to ${newPlan?.name}.`
                : type === 'downgrade'
                  ? `You are about to downgrade your plan to ${newPlan?.name}.`
                  : 'You are about to change your billing frequency.'}
            </p>
          </div>
          <div className="mt-lg min-w-full">
            {/* Alert */}
            {!buttonLoading &&
            !loadingUserData &&
            alert !== null &&
            alert.restrictActions.updatePlan ? (
              <div className="flex w-full items-center justify-center">
                <div className="flex flex-col items-center rounded-md border border-warning/70 bg-warning/20 p-md">
                  <div>
                    <p className="text-center font-bold text-warning-foreground">
                      You cannot update your plan at this time.
                    </p>
                  </div>
                  <p className="body2 mt-sm max-w-paragraph text-center text-warning-foreground">
                    {alertMessages[alert.type]}
                  </p>
                  <div className="mt-lg self-center">
                    <Button
                      variant="outline"
                      onClick={() => {
                        router.push(ROUTES.SETTINGS.BILLING);
                        onOpenChange(false);
                      }}
                    >
                      Go to Settings
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex h-full flex-nowrap ">
                {/* Payment Info */}
                <div className="h-full w-1/2">
                  <div className="flex h-full flex-col flex-nowrap ">
                    <div className="flex items-center justify-between">
                      <p className="subtitle2 text-muted ">
                        Payment Information
                      </p>
                      {paymentMethods && (
                        <div>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              router.push(ROUTES.SETTINGS.BILLING);
                              onOpenChange(false);
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-md w-full">
                      <div className="flex flex-col flex-nowrap">
                        {paymentMethods ? (
                          <div>
                            <PaymentMethodCard
                              paymentMethod={
                                paymentMethods as CardPaymentMethodObject
                              }
                            />
                          </div>
                        ) : (
                          <PaymentForm
                            setIsComplete={setIsPaymentInfoComplete}
                            postalCodeState={[
                              postalCode,
                              setPostalCode
                            ]}
                            countryCodeState={[
                              countryCode,
                              setCountryCode
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Invoice Preview */}
                <div className="ml-lg h-full w-1/2 border-l pl-lg">
                  <div className="flex w-full flex-col flex-nowrap">
                    <p className="subtitle2 mb-md text-muted">
                      Invoice Preview
                    </p>
                    {/* Billing Options */}
                    <div className="mb-md">
                      <div className="flex w-full items-center">
                        <RadioGroup
                          value={billingFrequency}
                          onValueChange={(value) =>
                            setBillingFrequency(
                              value as SubscriptionFrequenciesEnum
                            )
                          }
                        >
                          <div className="flex items-center">
                            {[
                              SubscriptionFrequenciesEnum.Month,
                              SubscriptionFrequenciesEnum.Year
                            ].map((option, idx) => {
                              return (
                                <div
                                  className={
                                    'flex flex-col flex-nowrap justify-between ' +
                                    (idx !== 0 && 'ml-lg')
                                  }
                                >
                                  <div className="flex flex-nowrap items-center">
                                    <RadioGroupItem
                                      value={option}
                                      key={getListItemKey(idx)}
                                      disabled={
                                        disableBillingOption ===
                                        option
                                      }
                                    />
                                    <p
                                      className={
                                        'ml-sm ' +
                                        (disableBillingOption ===
                                        option
                                          ? 'text-muted'
                                          : '')
                                      }
                                    >
                                      {option ===
                                      SubscriptionFrequenciesEnum.Month
                                        ? 'Monthly billing'
                                        : 'Annual billing (save 10%)'}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                    {/* Promotion Code */}
                    <div className="mb-sm flex w-full flex-nowrap items-end">
                      <div className="w-full">
                        <div className="flex w-full flex-col flex-nowrap">
                          <Input
                            prefixText="Promotion Code: "
                            value={promotionCode.editValue}
                            onChange={(e) => {
                              setInvalidPromotionCode(false);
                              setPromotionCode({
                                ...promotionCode,
                                editValue: e.target.value
                              });
                            }}
                          />
                        </div>
                      </div>
                      <Button
                        variant="outline"
                        className="ml-sm"
                        onClick={() =>
                          // Submit promotion code
                          setPromotionCode({
                            ...promotionCode,
                            submittedValue: promotionCode.editValue
                          })
                        }
                      >
                        Apply
                      </Button>
                    </div>
                    {invalidPromotionCode && (
                      <div className="">
                        <p className="body2 text-destructive">
                          Invalid promotion code.
                        </p>
                      </div>
                    )}
                    {/* Invoice Preview */}
                    <div className="mt-lg w-full">
                      <InvoicePreview
                        newPlan={newPlan}
                        promotionCode={promotionCode.submittedValue}
                        setInvalidPromotionCode={
                          setInvalidPromotionCode
                        }
                        postalCode={postalCode}
                        countryCode={countryCode}
                        billingFrequency={billingFrequency}
                        subscriptionError={subscriptionError}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Dialog Buttons */}
        <DialogFooter className="mt-md">
          <div className="flex items-center">
            <Button
              variant="outline"
              className="mr-sm"
              onClick={() =>
                window.open(ROUTES.RESOURCES.PRICING, '_blank')
              }
            >
              Learn More
            </Button>
            <Button
              onClick={async () => {
                await submitPayment();
              }}
              loading={buttonLoading}
              disabled={
                (!paymentMethods && !isPaymentInfoComplete) ||
                alert?.restrictActions?.updatePlan ||
                (!paymentMethods && (!countryCode || !postalCode))
              }
            >
              {'Submit Payment'}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default function SubscribeDialog(props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  newPlan: SubscriptionPlanType;
  initialBillingOption?: SubscriptionFrequenciesEnum;
  type: 'upgrade' | 'downgrade' | 'edit-billing';
  disableBillingOption?: SubscriptionFrequenciesEnum;
  refetchBillingViewQuery?: () => void;
}) {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'subscription',
        // Amount/currency is required for the PaymentElement to render
        // This is just an arbitrary amount, actual PaymentIntent gets created when Subscription is created
        amount: 100,
        currency: 'usd',
        fonts: [
          {
            family: 'TTHoves',
            src: "url('/public/fonts/TTHoves/TTHoves-Bold-Italic.woff')",
            weight: '300'
          }
        ],
        appearance: STRIPE_ELEMENTS_APPEARANCE
      }}
    >
      <Inner {...props} />
    </Elements>
  );
}
