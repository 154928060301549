import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { ButtonType } from 'src/@types/button';
import { MaxWidthType } from 'src/@types/dimensions';
import { TopbarLogo } from 'src/components/core/atoms/Logo';
import {
  ANIMATION_DURATION,
  SCROLLBAR_WIDTH_PX,
  TOPBAR_HEIGHT_PX,
  Z_INDEX
} from 'src/config';
import { useDimensions } from 'src/hooks/useDimensions';
import TopbarButtons from 'src/layouts/Topbar/TopbarButtons';

// ----------------------------------------------------------------------

export default function DefaultDesktopTopbar({
  mainButtons,
  actionButtons,
  maxWidth
}: {
  includeSearch?: boolean;
  mainButtons?: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  actionButtons?: ButtonType[];
  maxWidth?: MaxWidthType;
}) {
  // const {
  //   authState: { isAuthenticated }
  // } = useAuthPersistStore((store: AuthStoreType) => ({
  //   authState: store.authState
  // }));
  // const router = useRouter();
  const [dropdownIdx, setDropdownIdx] = useState({
    in: null,
    out: null
  });
  const theme = useTheme();
  const { width } = useDimensions();

  const minWidth = Math.max(
    maxWidth !== null
      ? (width - theme.breakpoints.values[maxWidth]) / 2
      : 0,
    0
  );

  useEffect(() => {
    if (dropdownIdx.out !== null) {
      setTimeout(
        () => setDropdownIdx({ in: null, out: null }),
        ANIMATION_DURATION
      );
    }
  }, [dropdownIdx]);

  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        flexWrap: 'nowrap',
        minHeight: TOPBAR_HEIGHT_PX,
        maxHeight: TOPBAR_HEIGHT_PX,
        justifyContent: 'space-between',
        backgroundColor: 'background.paper',
        width: '100%',
        mr: SCROLLBAR_WIDTH_PX,
        // pl: tailwindTheme.spacing["md"],
        // borderBottom: 1,
        // borderBottomColor: 'border.main',
        height: '100%'
      }}
    >
      <div>
        {/* Left inc. Logo */}
        <TopbarLogo />
      </div>
      {/* Center */}
      <Grid
        item
        container
        sx={{
          flexGrow: 1,
          flexWrap: 'nowrap',
          pl: 1,
          minHeight: TOPBAR_HEIGHT_PX,
          maxHeight: TOPBAR_HEIGHT_PX,
          alignItems: 'center'
        }}
      >
        <Grid
          item
          sx={{
            zIndex: Z_INDEX.TOPBAR + 1,
            minHeight: TOPBAR_HEIGHT_PX,
            maxHeight: TOPBAR_HEIGHT_PX,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TopbarButtons
            buttons={mainButtons}
            sx={{ transform: 'translateX(-1px)' }}
            isLeft={true}
          />
        </Grid>
        <Grid
          item
          container
          sx={{
            zIndex: Z_INDEX.TOPBAR + 1,
            flexGrow: 1,
            justifyContent: 'flex-end',
            minHeight: TOPBAR_HEIGHT_PX,
            maxHeight: TOPBAR_HEIGHT_PX,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TopbarButtons
            buttons={actionButtons.map((actionButton) => {
              return {
                component: actionButton,
                dropdown: []
              };
            })}
            sx={{ transform: 'translateX(1px)' }}
            isLeft={false}
          />
        </Grid>
      </Grid>

      {/* Right */}
      <Grid item sx={{ minWidth: minWidth, height: '100%' }} />
    </Grid>
  );
}
