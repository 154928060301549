import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Popper,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import Checkbox from 'src/components/core/atoms/Checkbox';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { useDisableEscKeyStore } from './Dialog';

export type OptionsType = { [key: string]: string }; // { identifier: name }
export type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'top-end'
  | 'top-start'
  | 'top';

type SelectPopperProps = {
  value?: string;
  options: OptionsType;
  onExit?: () => void;
  selectedOptionsState?: [OptionsType, (value: OptionsType) => void];
  onChange: (value: string) => void;
  isOpenState?: [boolean, (value: boolean) => void];
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  buttonCallback?: (
    selectedOptions: OptionsType,
    setSelectedOptions
  ) => void;
  renderButtonText?: (selectedOptions: OptionsType) => string;
  hideSearchBar?: boolean;
  renderAnchorEl?: (value: string) => JSX.Element;
  multiSelect?: boolean;
  minWidth?: number;
  maxHeight?: number;
  initialSelectedOptions?: OptionsType;
  disableCloseOnButtonClick?: boolean;
  disabled?: boolean;
  renderOption?: (key: string) => JSX.Element;
  containerSx?: object;
  loading?: boolean;
  backgroundColor?: string;
  stopPropagation?: boolean;
  placement?: PlacementType;
};

export default function SelectPopper({
  value,
  options,
  selectedOptionsState = null,
  onExit = () => null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  onChange,
  isOpenState = [null, () => null],
  renderAnchorEl,
  disabled = false,
  hideSearchBar = false,
  renderOption,
  initialSelectedOptions,
  renderButtonText,
  disableCloseOnButtonClick,
  buttonCallback,
  multiSelect = false,
  containerSx,
  minWidth = 200,
  maxHeight = 400,
  loading = false,
  backgroundColor = 'background.neutral',
  stopPropagation = false,
  placement = 'bottom-start'
}: SelectPopperProps) {
  const theme = useTheme();
  const mainRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isOpen, setIsOpen] = isOpenState;

  const open = Boolean(
    isOpen !== null ? isOpen && anchorEl : anchorEl
  );

  // If multiselect
  let selectedOptions = null;
  let setSelectedOptions = null;
  if (selectedOptionsState !== null) {
    // If selectedOptionsState is passed in, use that
    [selectedOptions, setSelectedOptions] = selectedOptionsState;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [selectedOptions, setSelectedOptions] = useState<OptionsType>(
      initialSelectedOptions
    );
  }

  // TODO: this is awkward because we are checking for the presence of a key
  // This means there is some duplicated logic between this and initialSelectedOptions
  function isSelected(value: string) {
    return Object.keys(selectedOptions || {}).includes(value);
  }

  const [filteredOptions, setFilteredOptions] =
    useState<OptionsType>(options);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions);
    setFilteredOptions(options);
    setSearchQuery('');
  }, [open, options]);

  const { setDisableEscKey } = useDisableEscKeyStore((store) => ({
    setDisableEscKey: store.setDisableEscKey
  }));

  const handleClick = () => {
    const disableAnchor = anchorEl || isOpen === false;

    if (disableAnchor) {
      setDisableEscKey(false);
    } else {
      setDisableEscKey(true);
    }

    setAnchorEl(disableAnchor ? null : !disabled && mainRef.current);
  };

  useEffect(() => {
    if (isOpen !== null) {
      handleClick();
    }
  }, [isOpen]);

  const handleOptionClick = (key: string) => {
    onChange(key);
    if (!multiSelect) {
      onClose();
    } else {
      // update new selected options
      const newSelectedOptions = { ...selectedOptions };
      if (isSelected(key)) {
        delete newSelectedOptions[key];
      } else {
        newSelectedOptions[key] = options[key];
      }
      setSelectedOptions({ ...newSelectedOptions });
    }
  };

  const BUTTON_HEIGHT = 62;
  const SEARCH_BAR_HEIGHT = 60;
  const maxContentHeight =
    maxHeight -
    (buttonCallback ? BUTTON_HEIGHT : 0) -
    (!hideSearchBar ? SEARCH_BAR_HEIGHT : 0);

  const refs = useRef({});
  const inputRef = useRef(null);
  const scrollableAreaRef = useRef(null);
  const activeRefIdx = useRef(-1);

  // Add an up/down key listener that is active when the popper is open
  const handleKeyDown = (e) => {
    const keys = Object.keys(filteredOptions);
    const idx = activeRefIdx.current;

    // check if escape key was pressed
    if (e.code === 'Escape') {
      onClose();
      // prevent the event from bubbling up
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    } else if (e.key === 'ArrowDown') {
      setIsMouseMoving(false);
      if (idx < keys.length - 1) {
        activeRefIdx.current = idx + 1;

        if (activeRefIdx.current === 0) {
          scrollableAreaRef.current.style.overflowY = 'clip';
        }

        refs.current[keys[activeRefIdx.current]].focus({
          preventScroll: true
        });
        if (activeRefIdx.current === 0) {
          setTimeout(() => {
            scrollableAreaRef.current.style.overflowY = 'scroll';
          }, 100);
        }

        // scroll into view at the bottom of the scrollable area
        // const activeRef = refs.current[keys[activeRefIdx.current]];
      }
    } else if (e.key === 'ArrowUp') {
      setIsMouseMoving(false);
      if (idx > 0) {
        activeRefIdx.current = idx - 1;
        refs.current[keys[activeRefIdx.current]].focus();
      } else {
        activeRefIdx.current = -1;
        inputRef.current.focus();
      }
    }
  };
  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [open, filteredOptions, value]);

  const onClose = () => {
    activeRefIdx.current = -1;
    setIsOpen(false);
    setDisableEscKey(false);
    onExit && onExit();
    setAnchorEl(null);
  };

  const [isMouseMoving, setIsMouseMoving] = useState(false);

  const popper = (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{
        // Note that some parts of the app have higher stacking contexts and will
        // appear above this popper. This unintentional and should be fixed.
        zIndex: 1000000,
        backgroundColor,
        border: 1,
        borderColor: 'border.main',
        borderRadius: 1,
        minWidth,
        maxHeight,
        boxShadow:
          '0px 0px 30px 5px ' + theme.palette.background.boxShadow
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]}
      placement={placement}
    >
      <Box
        sx={{
          minWidth: '100%',
          width: '100%',
          height: '100%',
          maxHeight: maxHeight
        }}
        onMouseEnter={(e) => {
          onMouseEnter && onMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeave && onMouseLeave(e);
        }}
      >
        {/* Search bar */}
        {!hideSearchBar && (
          <Box>
            <InputBase
              autoFocus
              inputRef={inputRef}
              onFocus={() => {
                activeRefIdx.current = -1;
              }}
              value={searchQuery}
              disabled={loading}
              fullWidth
              placeholder={'Search...'}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                const newFilteredOptions = {};

                Object.keys(options).forEach((key) => {
                  if (
                    key
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    options[key]
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  ) {
                    newFilteredOptions[key] = options[key];
                  }
                });
                setFilteredOptions(newFilteredOptions);
              }}
              inputProps={{
                sx: {
                  fontSize: 'caption.fontSize',
                  fontFamily: 'caption.fontFamily',
                  fontWeight: 'caption.fontWeight',
                  borderBottom: 1,
                  borderColor: 'divider',
                  pl: 2,
                  py: 1.5,
                  // mb: 1,
                  '&.Mui-disabled': {
                    WebkitTextFillColor: `${theme.palette.common.white} !important`
                  }
                }
              }}
            />
          </Box>
        )}

        {loading === true &&
          ['skeleton-1', 'skeleton-2', 'skeleton-3'].map(
            (key, idx) => {
              return (
                <Grid
                  item
                  key={key}
                  sx={{ width: '100%', mt: idx === 0 ? 0 : 1, px: 1 }}
                >
                  <Skeleton height="32px" />
                </Grid>
              );
            }
          )}

        {/* Content */}
        <Grid item sx={{}}>
          <Grid
            ref={scrollableAreaRef}
            // container
            sx={{
              flexDirection: 'column',
              display: 'flex',
              maxHeight: maxContentHeight,
              flexWrap: 'nowrap',
              overflowY: 'scroll'
            }}
            onMouseMoveCapture={() => {
              setIsMouseMoving(true);
            }}
          >
            {Object.keys(filteredOptions || {}).length > 0 ? (
              <>
                <Box sx={{ pt: 1 }} />
                {Object.keys(filteredOptions).map((key, idx) => {
                  return (
                    !loading && (
                      <MenuItem
                        ref={(ref) => {
                          refs.current[key] = ref;
                        }}
                        onMouseEnter={() => {
                          isMouseMoving &&
                            (activeRefIdx.current = idx);
                        }}
                        key={key}
                        onClick={() => {
                          handleOptionClick(key);
                          if (!multiSelect) {
                            onClose();
                          }
                        }}
                        sx={{
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          color:
                            value === key && !loading
                              ? 'primary.main'
                              : 'text.primary',
                          '&:hover': {
                            backgroundColor: isMouseMoving
                              ? 'background.default'
                              : backgroundColor
                          },
                          '&:focus': {
                            backgroundColor: 'background.default'
                          },
                          borderRadius: 1,
                          mx: 1,
                          px: 1
                        }}
                      >
                        {multiSelect && (
                          <div>
                            <Checkbox
                              onClick={() => {
                                handleOptionClick(key);
                              }}
                              selected={isSelected(key)}
                            />
                          </div>
                        )}
                        <Grid
                          item
                          sx={{
                            flexGrow: 1,
                            maxWidth: 'calc(100% - 45px)',
                            pl: 1
                          }}
                        >
                          {renderOption ? (
                            renderOption(key)
                          ) : (
                            <Typography
                              variant="body2"
                              color={
                                value === key
                                  ? 'primary.main'
                                  : 'inherit'
                              }
                            >
                              {options[key]}
                            </Typography>
                          )}
                        </Grid>
                      </MenuItem>
                    )
                  );
                })}
                <Box sx={{ pt: 1 }} />
              </>
            ) : (
              <Grid item sx={{ px: 2, pb: 1 }}>
                <Typography variant="caption">No results.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Button */}
      {buttonCallback && (
        <Grid
          item
          sx={{
            p: 1,
            borderTop: 1,
            borderTopColor: 'border.main',
            height: BUTTON_HEIGHT
          }}
          onMouseDown={(e) => {
            buttonCallback(selectedOptions, setSelectedOptions);
            if (!disableCloseOnButtonClick) {
              onClose();
            }
            e.stopPropagation();
          }}
        >
          <Button variant="outline" className="w-full">
            {renderButtonText
              ? renderButtonText(selectedOptions)
              : 'Save'}
          </Button>
        </Grid>
      )}
    </Popper>
  );

  return (
    <>
      {/* Anchor element */}
      <Box
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          handleClick();
        }}
        sx={containerSx}
        ref={mainRef}
      >
        {renderAnchorEl ? (
          renderAnchorEl(value)
        ) : (
          <Grid
            container
            sx={{
              flexWrap: 'nowrap',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Grid item>
              <Typography variant="body2">
                {options[value]}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton disabled={disabled}>
                <ExpandMore />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Popper */}
      {open ? (
        <ClickAwayListener onClickAway={onClose}>
          {popper}
        </ClickAwayListener>
      ) : (
        popper
      )}
    </>
  );
}
