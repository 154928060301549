import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer,
  Grid,
  IconButton,
  Dialog as MDialog,
  PaperProps,
  Typography
} from '@mui/material';
import {
  BranchSelectorTreeQueryQuery,
  GoalFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import BranchSelector from 'src/components/carbon/molecules/BranchSelector';
import GoalSelector from 'src/components/core/molecules/GoalSelector';
import { Button } from 'src/components/shad-base/button';
import useDevice from 'src/hooks/useDevice';
import { create } from 'zustand';

//---------------------------------------------------------------------------

type DisableEscKeyStoreType = {
  disableEscKey: boolean;
  setDisableEscKey: (disableEscKey: boolean) => void;
};
export const useDisableEscKeyStore = create<DisableEscKeyStoreType>(
  (set) => ({
    disableEscKey: false,
    setDisableEscKey: (disableEscKey) => {
      set({ disableEscKey });
    }
  })
);

export default function Dialog<T>({
  open,
  onClose,
  title,
  renderTitle,
  renderTitleOverline,
  toggles = null,
  activeBranchIdentifierState = null,
  activeGoalState = null,
  // initialBranch=null,
  disableActiveBranch = false,
  disableActiveGoal = false,
  disableToggles = false,
  children,
  maxWidth = 'md',
  fullScreen = false,
  paperSx,
  minWidth = 400,
  altButtons = [],
  buttons = [],
  invertButtonColors = false,
  getIsValidBranch = null,
  // Some components will need more than just the branch identifier, so optionally we will pass a setter for the whole branch object
  setActiveBranch
}: {
  open: boolean;
  onClose: (e) => void;
  title?: string;
  renderTitle?: () => React.ReactNode;
  renderTitleOverline?: () => React.ReactNode;
  toggles?: {
    modes: T[];
    selectedMode: T;
    setSelectedMode: React.Dispatch<React.SetStateAction<T>>;
  };
  activeBranchIdentifierState?: [
    string,
    React.Dispatch<React.SetStateAction<string>>
  ];
  activeGoalState?: [
    GoalFragmentFragment | any,
    React.Dispatch<React.SetStateAction<GoalFragmentFragment | any>>
  ];
  initialBranch?: T;
  disableToggles?: boolean;
  disableActiveBranch?: boolean;
  disableActiveGoal?: boolean;
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  paperSx?: PaperProps['sx'];
  fullScreen?: boolean;
  minWidth?: number;
  altButtons?: {
    key: string;
    label: string;
    disabled?: boolean;
    onClick: () => void;
    variation: 'continue' | 'cancel' | 'back' | 'alternative';
    invertColors?: boolean;
  }[];
  buttons?: {
    key: string;
    label: string;
    disabled?: boolean;
    onClick: () => void;
    variation: 'continue' | 'cancel' | 'back' | 'alternative';
    invertColors?: boolean;
    loading?: boolean;
  }[];
  invertButtonColors?: boolean;
  getIsValidBranch?: (
    branch: BranchSelectorTreeQueryQuery['tree']['data']['branches'][0]
  ) => boolean;
  setActiveBranch?: (
    branch: BranchSelectorTreeQueryQuery['tree']['data']['branches'][0]
  ) => void;
}) {
  const { isMobile } = useDevice();

  const { disableEscKey } = useDisableEscKeyStore();

  const getButtonColor = (button) => {
    switch (button.variation) {
      case 'continue':
        return invertButtonColors ? 'error' : 'primary';
      case 'cancel':
        return invertButtonColors ? 'primary' : 'error';
      case 'back':
        return 'inherit';
    }
  };
  const getButtonVariation = (button) => {
    switch (button.variation) {
      case 'continue':
        return 'default';
      case 'alternative':
        return 'outline';
      case 'cancel':
        return 'outline';
      case 'back':
        return 'outline';
    }
  };

  let buttonsToRender = buttons;
  const backButton = buttons.find(
    (button) => button.variation === 'back'
  );
  if (backButton) {
    buttonsToRender = buttons.filter(
      (button) => button.variation !== 'back'
    );
  }

  let activeBranchIdentifier = null;
  let setActiveBranchIdentifier = null;
  if (activeBranchIdentifierState) {
    [activeBranchIdentifier, setActiveBranchIdentifier] =
      activeBranchIdentifierState;
  }

  let activeGoal = null;
  let setActiveGoal = null;
  if (activeGoalState) {
    [activeGoal, setActiveGoal] = activeGoalState;
  }

  return isMobile ? (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={onClose}
      PaperProps={{
        sx: {
          height: 'calc(100% - 80px)',
          top: 80
        }
      }}
    >
      <Grid container sx={{ flexDirection: 'column', p: 3 }}>
        <Grid item>
          {(title || renderTitle) && (
            <Grid
              container
              sx={{
                alignItems: 'center',
                pb: 1,
                mb: 2,
                borderBottom: 1,
                borderColor: 'divider',
                color: 'text.primaryAlt1'
              }}
            >
              {renderTitle ? (
                <>{renderTitle()}</>
              ) : (
                <Typography variant="subtitle1">{title}</Typography>
              )}
            </Grid>
          )}
          <Grid item>{children}</Grid>
        </Grid>
        <Grid item>
          {/* Action buttons */}
          <Grid
            container
            sx={{
              justifyContent: 'flex-end',
              mt: 6
            }}
          >
            {buttonsToRender.map((button) => {
              return (
                <Grid item key={button.key} sx={{ ml: 1 }}>
                  <Button
                    disabled={button.disabled || false}
                    variant={getButtonVariation(button)}
                    color={getButtonColor(button)}
                    onClick={button.onClick}
                    loading={button.loading}
                  >
                    {button.label}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  ) : (
    <MDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      disableEscapeKeyDown={disableEscKey}
      slotProps={{ backdrop: { sx: { background: 'transparent' } } }}
      PaperProps={{
        sx: {
          ...paperSx,
          minWidth: minWidth,
          maxWidth: maxWidth,
          backgroundColor: 'background.neutral',
          border: 1,
          borderColor: 'border.main'
        }
      }}
    >
      <Grid
        container
        sx={{ flexDirection: 'column', flexWrap: 'nowrap' }}
      >
        <Grid item sx={{ p: 0 }}>
          <Grid
            container
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              flexWrap: 'nowrap'
            }}
          >
            <Grid
              item
              sx={{
                pl: 4,
                pt: 3,
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Grid item sx={{ mt: 0 }}>
                {renderTitleOverline && renderTitleOverline()}
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item sx={{ flexGrow: 1 }}>
                  {renderTitle ? (
                    <>{renderTitle()}</>
                  ) : (
                    <Typography variant="subtitle1">
                      {title}
                    </Typography>
                  )}
                </Grid>
                {toggles && (
                  <Grid item sx={{ mr: 1 }}>
                    <MultiSwitch
                      options={toggles.modes.map((mode) => {
                        return mode as string;
                      })}
                      disabled={disableToggles}
                      activeOption={toggles.selectedMode as string}
                      setActiveOption={(option) => {
                        toggles.setSelectedMode(option as T);
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sx={{ ml: 0, transform: 'translateY(0px)' }}
                >
                  {activeBranchIdentifierState &&
                    !disableActiveBranch && (
                      <>
                        <BranchSelector
                          required
                          activeBranchIdentifier={
                            activeBranchIdentifier
                          }
                          setActiveBranchIdentifier={
                            setActiveBranchIdentifier
                          }
                          getIsValidBranch={getIsValidBranch}
                          disableClear
                          prefixText={'Location: '}
                          setBranch={setActiveBranch}
                        />
                      </>
                    )}
                  {activeGoalState && !disableActiveGoal && (
                    <GoalSelector
                      required
                      activeGoal={activeGoal}
                      setActiveGoal={setActiveGoal}
                      disableClear
                      prefixText={'Goal: '}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 3, mr: 3, ml: 2 }}>
              <IconButton
                aria-label="close"
                onClick={onClose}
                tabIndex={1}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ px: 4, pb: 4, mt: 2 }}>
          {children}
        </Grid>
        {(backButton || buttonsToRender?.length > 0) && (
          <Grid
            item
            sx={{
              // mt: buttons.length > 0 ? 3 : 0,
              // borderTop: 1,
              borderColor: 'border.main',
              p: 4,
              pt: 0
            }}
          >
            <Grid
              container
              sx={{
                alignItems: 'center',
                flexWrap: 'nowrap',
                justifyContent:
                  backButton || altButtons.length > 0
                    ? 'space-between'
                    : 'flex-end'
              }}
            >
              {(backButton || altButtons.length > 0) && (
                <Grid item container sx={{}}>
                  {backButton && (
                    <Grid item sx={{ mr: 1 }}>
                      <Button
                        variant="outline"
                        onClick={() => backButton.onClick()}
                      >
                        {backButton.label}
                      </Button>
                    </Grid>
                  )}
                  {altButtons.map((button) => {
                    return (
                      <Grid item key={button.key} sx={{ mr: 2 }}>
                        <Button
                          disabled={button.disabled || false}
                          variant={getButtonVariation(button)}
                          color={getButtonColor(button)}
                          onClick={button.onClick}
                        >
                          {button.label}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              {buttonsToRender.map((button) => {
                return (
                  <Grid item key={button.key} sx={{ ml: 1 }}>
                    <Button
                      disabled={button.disabled || false}
                      variant={getButtonVariation(button)}
                      color={getButtonColor(button)}
                      onClick={button.onClick}
                      loading={button.loading}
                    >
                      {button.label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </MDialog>
  );
}
