import { Box, ClickAwayListener, Popper, PopperProps } from '@mui/material';
import { alpha, keyframes, styled } from '@mui/system';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ANIMATION_DURATION, Z_INDEX } from 'src/config';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: Z_INDEX.MENU_POPPER,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.default,
    borderRight: `solid 1px ${theme.palette.common.black}`,
    borderBottom: `solid 1px ${theme.palette.common.black}`
  }
}));

// ----------------------------------------------------------------------

export default function MenuPopper({
  children,
  sx,
  onClose,
  open,
  placement = 'bottom-end',
  arrow = true,
  // modifiers = [
  //   {
  //     name: 'offset',
  //     options: {
  //       offset: [0, 20]
  //     }
  //   }
  // ],
  ...other
}: {
  children: ReactNode;
  onClose: () => void;
  arrow?: boolean;
  placement?: PopperProps['placement'];
} & PopperProps) {
  const containerRef = useRef();
  const [isOpenState, setIsOpenState] = useState({
    isOpen: open,
    isFadingOut: false
  });
  useEffect(() => {
    setIsOpenState({ ...isOpenState, isOpen: open });
  }, [open]);

  const [rerender, setRerender] = useState(false);
  useEffect(() => {
    setRerender(!rerender);
  }, [other?.anchorEl]);

  return other?.anchorEl != null ? (
    <Box ref={containerRef}>
      <ClickAwayListener
        onClickAway={() => {
          if (isOpenState.isOpen) {
            setIsOpenState({ isOpen: false, isFadingOut: true });
            onClose();
            setTimeout(() => {
              setIsOpenState({ isOpen: false, isFadingOut: false });
            }, ANIMATION_DURATION);
          }
        }}
      >
        <Popper
          keepMounted
          placement={placement}
          container={containerRef.current}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8 * 2]
              }
            }
          ]}
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 1,
            p: 0,
            overflow: 'clip',
            boxShadow: `0 5px 10px 0px ${alpha('#000000', 0.16)}`,
            zIndex: Z_INDEX.MENU_POPPER,
            animation:
              isOpenState.isOpen && !isOpenState.isFadingOut
                ? keyframes`
                  from {
                    opacity: 0
                  }
                  to {
                    opacity: 1
                  }` + ` ${ANIMATION_DURATION}ms`
                : keyframes`
                  from {
                    opacity: 1
                  }
                  to {
                    opacity: 0
                  }` + ` ${ANIMATION_DURATION}ms`,
            animationFillMode: 'forwards',
            ...sx
          }}
          open={isOpenState.isOpen || isOpenState.isFadingOut}
          {...other}
        >
          <>
            {arrow ? <ArrowStyle /> : null}
            {children}
          </>
        </Popper>
      </ClickAwayListener>
    </Box>
  ) : null;
}
