import ExpandMore from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import SelectPopper, {
  PlacementType
} from 'src/components/core/molecules/SelectPopper';
import useOnboarding, {
  OnboardingType
} from 'src/hooks/store/useOnboardingStore';

import getIntercom, { INTERCOM_ARTICLES } from 'src/utils/intercom';

export default function OnboardingSectionSelector({
  placement = 'bottom-start',
  isOpenState = undefined,
  disabled = false,
  renderCurrentSection = () => null
}: {
  placement?: PlacementType;
  isOpenState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  disabled?: boolean;
  renderCurrentSection?: (section: string) => JSX.Element;
}) {
  if (renderCurrentSection === null) {
    renderCurrentSection = (branch) => {
      return (
        <>
          <>
            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'nowrap',
                cursor: 'pointer',
                borderBottom: 1,
                borderColor: 'divider',
                pb: 1
              }}
            >
              <Grid item>
                <Typography variant="subtitle1" noWrap={true}>
                  {branch}
                </Typography>
              </Grid>
              <Grid item>
                <ExpandMore />
              </Grid>
            </Grid>
          </>
        </>
      );
    };
  }

  const { active, currentSection } = useOnboarding(
    (store: OnboardingType) => ({
      active: store.active,
      currentSection: store.currentSection
    })
  );

  const sectionOptions = {
    'Getting Started': 'Getting Started'
    // 'Create a Measurement': 'Create a Measurement'
  };

  // const router = useRouter();
  const intercom = getIntercom();
  return (
    <>
      <SelectPopper
        isOpenState={isOpenState}
        options={sectionOptions}
        disabled={disabled}
        placement={placement as PlacementType}
        onChange={() => {
          intercom.showArticle(INTERCOM_ARTICLES['Getting Started']);
        }}
        value={active ? currentSection : null}
        renderAnchorEl={() => {
          return renderCurrentSection(currentSection);
        }}
        renderOption={(option) => {
          return (
            <Grid
              container
              sx={{
                alignItems: 'center',
                flexWrap: 'nowrap',
                px: 0
              }}
            >
              <Grid item>
                <Typography
                  variant="caption"
                  noWrap
                  color={
                    active && currentSection === option
                      ? 'primary.main'
                      : 'text.primary'
                  }
                >
                  {option}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
        initialSelectedOptions={{}}
        loading={false}
        minWidth={600}
      />
    </>
  );
}
