import { useRouter } from 'next/router';
import { Logo } from 'src/components/core/atoms/Logo';
import PaywallDialog from 'src/components/core/organisms/dialogs/PaywallDialog';
import { Button } from 'src/components/shad-base/button';
import { ROUTES } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';
import useSubscriptionStore, {
  SubscriptionStoreType
} from 'src/hooks/store/useSubcriptionStore';
import useDevice from 'src/hooks/useDevice';
import { LayoutProps } from 'src/layouts/Layout';
import SidebarNav from 'src/layouts/Layout/SidebarNav';

// User is fully authenticated and page is private
export default function InternalLayout({
  children,
  getSubnav,
  disableLayoutElements
}: LayoutProps) {
  const { isMobile } = useDevice();
  const router = useRouter();
  const { sidebarMinimized } = useAppStore((store) => ({
    sidebarMinimized: store.sidebarMinimized
  }));
  const {
    paywallDialogOpen,
    setPaywallDialogOpen,
    requiredPlan,
    subtitleText
  }: SubscriptionStoreType = useSubscriptionStore();

  document.body.style.overscrollBehaviorX = 'none';
  document.body.style.overscrollBehaviorY = 'none';

  return isMobile ? (
    <div className="flex h-screen max-h-screen flex-col items-center justify-center p-lg">
      <Logo />
      <div className="mb-lg mt-xl">
        <p className="text-center">
          EmitIQ Mobile is coming soon! In the meantime, please
          continue on a larger device.
        </p>
      </div>
      <Button onClick={() => router.push(ROUTES.ROOT)}>
        Back to Home
      </Button>
    </div>
  ) : (
    <>
      <div className="flex h-full w-full flex-nowrap">
        {/* Sidebar Nav */}
        {!disableLayoutElements && (
          <div id="sidebar-nav">
            <SidebarNav />
          </div>
        )}
        {/* Page Content */}
        <div
          className={
            disableLayoutElements
              ? 'w-full'
              : sidebarMinimized
                ? 'w-left-min-sidebar-page'
                : 'w-left-sidebar-page'
          }
        >
          <div className="flex flex-col flex-nowrap">
            {getSubnav && (
              <div className="h-topbar max-h-topbar w-full">
                {getSubnav()}
              </div>
            )}
            <div
              className={
                'w-full ' +
                (disableLayoutElements
                  ? 'h-screen min-h-screen '
                  : getSubnav
                    ? ' h-page-height'
                    : '')
              }
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      {/* Paywall for internal pages */}
      <PaywallDialog
        open={paywallDialogOpen}
        onOpenChange={setPaywallDialogOpen}
        requiredPlan={requiredPlan}
        subtitleText={subtitleText}
      />
    </>
  );
}
