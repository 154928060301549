import { cn } from '@/lib/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  ButtonBase,
  Grid,
  Typography,
  keyframes
} from '@mui/material';
import { useRouter } from 'next/router';
import { Fragment, useRef, useState } from 'react';
import { ButtonType } from 'src/@types/button';
import {
  ANIMATION_DURATION,
  ROUTES,
  TOPBAR_HEIGHT_PX
} from 'src/config';
import { getListItemKey } from 'src/utils/format';
import MenuPopper from '../../components/core/molecules/MenuPopper';

// ----------------------------------------------------------------------

const ROTATE_IN = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(180deg);
}
`;

const ROTATE_OUT = keyframes`
from {
  transform: rotate(180deg);
}
to {
  transform: rotate(0deg);
}
`;

export default function TopbarButtons({
  buttons = [],
  sx = {}
}: // isLeft = false
{
  buttons: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  sx?: object;
  isLeft?: boolean;
}) {
  const router = useRouter();
  const dropdownRefs = useRef([]);
  const [dropdownIdx, setDropdownIdx] = useState({
    in: null,
    out: null
  });
  const [hoveringLabel, setHoveringLabel] = useState(
    'Carbon Accounting'
  );
  const [hoveringSections, setHoveringSections] = useState([
    { label: 'Overview', path: ROUTES.PRODUCTS.MEASUREMENT },
    {
      label: 'Reporting & Disclosure',
      path: ROUTES.PRODUCTS.REPORTING
    },
    { label: 'Marketplace', path: ROUTES.PRODUCTS.MARKETPLACE },
    { label: 'Directory', path: ROUTES.PRODUCTS.DIRECTORY }
  ]);

  return (
    <Grid
      container
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        width: '100%',
        minHeight: TOPBAR_HEIGHT_PX,
        maxHeight: TOPBAR_HEIGHT_PX,
        ...sx
      }}
    >
      {buttons.map((buttonInfo, index) => {
        return (
          <Fragment key={getListItemKey(index)}>
            <Grid
              item
              ref={(el) => (dropdownRefs.current[index] = el)}
              onClick={() => {
                if (buttonInfo.dropdown.length > 0) {
                  if (dropdownIdx.in === index) {
                    setDropdownIdx({
                      in: null,
                      out: dropdownIdx.in
                    });
                  } else {
                    setDropdownIdx({
                      in: index,
                      out: dropdownIdx.in
                    });
                  }
                }
              }}
              sx={{
                // mr: 1,
                position: 'relative',
                minHeight: TOPBAR_HEIGHT_PX,
                maxHeight: TOPBAR_HEIGHT_PX
                // borderRight: '1px dashed',
                // borderLeft: index === 0 ? '1px dashed' : 0,
                // borderColor: 'border.main'
              }}
            >
              {/* {index === 0 && isLeft && (
                <Box
                  sx={{
                    position: 'absolute',
                    height: TOPBAR_HEIGHT_PX,
                    bottom: -TOPBAR_HEIGHT + 'px',
                    left: -1,
                    borderRight: '1px dashed',
                    borderColor: 'border.main'
                  }}
                />
              )} */}
              {/* {index === buttons.length - 1 && !isLeft && (
                <Box
                  sx={{
                    position: 'absolute',
                    height: TOPBAR_HEIGHT_PX,
                    bottom: -TOPBAR_HEIGHT + 'px',
                    right: -1,
                    borderRight: '1px dashed',
                    borderColor: 'border.main'
                  }}
                />
              )} */}
              <ButtonBase
                // variation={buttonInfo.component.variation}
                // size="small"
                onClick={() => {
                  if (buttonInfo.dropdown.length == 0) {
                    router.push(buttonInfo.component.path);
                  }
                }}
                sx={{
                  height: '100%',
                  minHeight: TOPBAR_HEIGHT_PX,
                  maxHeight: TOPBAR_HEIGHT_PX,
                  px: 4,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: 'background.paper'
                  }
                }}
                // {...props}
              >
                <Grid
                  container
                  sx={{
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ flexWrap: 'nowrap', textWrap: 'nowrap' }}
                  >
                    {buttonInfo.component.name}
                  </Typography>
                  {buttonInfo.dropdown.length > 0 ? (
                    <ExpandMoreIcon
                      sx={{
                        ml: 1,
                        animation:
                          dropdownIdx.in === index
                            ? `${ROTATE_IN} ${ANIMATION_DURATION}ms`
                            : dropdownIdx.out === index
                              ? `${ROTATE_OUT} ${ANIMATION_DURATION}ms`
                              : null,
                        animationFillMode: 'forwards'
                      }}
                    />
                  ) : null}
                </Grid>
              </ButtonBase>
              {buttonInfo.component
                ?.getAbsolutelyPositionComponent && (
                <>
                  {buttonInfo.component?.getAbsolutelyPositionComponent()}
                </>
              )}
            </Grid>
            {buttonInfo.dropdown.length > 0 ? (
              <MenuPopper
                open={dropdownIdx.in === index}
                arrow={false}
                // placement="bottom-start"
                anchorEl={dropdownRefs.current[index]}
                onClose={() => {
                  setTimeout(() => {
                    if (dropdownIdx.in === index) {
                      setDropdownIdx({
                        in: null,
                        out: dropdownIdx.in
                      });
                    }
                  }, 50);
                }}
                sx={{
                  p: 2,
                  width: '750px',
                  height: '250px',
                  backgroundColor: 'background.default'
                }}
              >
                <div className="grid grid-cols-2">
                  <div className="mr-sm flex flex-col">
                    {buttonInfo.dropdown.map(
                      (
                        dropdownButton: ButtonType,
                        dropdownButtonIndex
                      ) => (
                        <div
                          className={cn(
                            'cursor-pointer rounded-xl p-sm hover:bg-card',
                            hoveringLabel === dropdownButton.name &&
                              'bg-card',
                            dropdownButton.name ===
                              'Carbon Removal Platform' &&
                              'cursor-default hover:bg-transparent'
                          )}
                          onMouseEnter={() => {
                            if (
                              dropdownButton.name !==
                              'Carbon Removal Platform'
                            ) {
                              setHoveringSections(
                                dropdownButton.sections
                              );
                              setHoveringLabel(dropdownButton.name);
                            }
                          }}
                          onClick={() => {
                            if (
                              dropdownButton.name !==
                              'Carbon Removal Platform'
                            ) {
                              router.push(dropdownButton.path);
                              setDropdownIdx({
                                in: null,
                                out: dropdownIdx.in
                              });
                            }
                          }}
                          key={dropdownButtonIndex}
                        >
                          <div
                            className={cn(
                              'flex w-full flex-row flex-nowrap items-center justify-between p-sm'
                            )}
                          >
                            <p>{dropdownButton.name}</p>
                            {dropdownButton.name ===
                            'Carbon Removal Platform' ? (
                              <div className="p-sm">
                                <p className="body2 text-primary">
                                  Soon
                                </p>
                              </div>
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex w-full flex-col">
                    <p className="p-sm text-sm text-gray-500">
                      {hoveringLabel}
                    </p>
                    <div>
                      {hoveringSections.map((section, sectionId) => {
                        return (
                          <div
                            className="cursor-pointer rounded-xl p-sm px-md hover:bg-card"
                            onClick={() => {
                              router.push(section.path);
                            }}
                            key={getListItemKey(sectionId)}
                          >
                            {section.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </MenuPopper>
            ) : null}
          </Fragment>
        );
      })}
    </Grid>
  );
}
