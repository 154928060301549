import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const onboardingSectionList = [
  'App navigation',
  'Dashboard navigation',
  'Create a measurement',
  'Create a reduction target',
  'Reduction action management',
  'Share page'
];

export const onboardingMaster = {
  'App navigation': [
    { id: 'Intro dialog' },
    { id: 'Dashboard' },
    { id: 'Carbon Inventory' },
    { id: 'Settings' }
  ],
  'Dashboard navigation': [
    { id: 'Badge' },
    { id: 'Checklist' },
    { id: 'Change view' }
  ],
  'Create a measurement': [
    { id: 'Intro dialog measurement' },
    { id: 'Add new' },
    { id: 'Mdialog' },
    // { id: 'Title' },
    // { id: 'Description' },
    // { id: 'Create' },
    { id: 'Measurement created dialog' }
  ],
  'Create a reduction target': [
    { id: 'Intro dialog reduction' },
    { id: 'Create goal' },
    { id: 'Continue to reduce' },
    { id: 'Title' },
    { id: 'Description' },
    { id: 'Baseline' },
    { id: 'Period' },
    { id: 'Percent reduction' },
    { id: 'Save goal' },
    { id: 'Goal created dialog' },
    { id: 'Create action' },
    { id: 'Action title' },
    { id: 'Action description' },
    { id: 'Ghg category' },
    { id: 'Status' },
    { id: 'Start date' },
    { id: 'End date' },
    { id: 'Save action' },
    { id: 'Action created dialog' }
  ],
  'Reduction action management': [
    { id: 'Change view' },
    { id: 'Select branch' }
  ],
  'Share page': [
    { id: 'Edit' },
    { id: 'Publish' },
    { id: 'Share page published dialog' }
  ]
};

export type onboardingProgressType = { [key: string]: number };

export type OnboardingType = {
  currentSectionLength: number;
  active: boolean;
  setActive: (newState: boolean) => void;
  currentStep: string;
  currentIndex: number;
  setCurrentIndex: (
    direction: boolean,
    currentIndex: number,
    currentSection: string,
    onboardingProgress: onboardingProgressType,
    updateProgress: boolean
  ) => void;
  currentSection: string;
  setCurrentSection: (section: string) => void;
  onboardingProgress: onboardingProgressType;
  onboardingComplete: boolean;
  setOnboardingComplete: (newState) => void;
  clear: () => void;
};

const clearState = () => useOnboarding.persist.clearStorage();
const useOnboarding = create(
  persist<OnboardingType>(
    (set) => ({
      clear: () => {
        clearState();
        set(() => ({
          onboardingProgress: {
            'App navigation': 0,
            'Dashboard navigation': 0,
            'Create a measurement': 0,
            'Create a reduction target': 0,
            'Reduction action management': 0,
            'Share page': 0
          },
          currentIndex: 0,
          currentSection: 'App navigation',
          currentStep: 'Intro dialog',
          active: true,
          currentSectionLength: 4
        }));
      },
      currentSectionLength: 4,
      active: true,
      setActive: (newState) => {
        set({ active: newState });
      },
      onboardingProgress: {
        'App navigation': 0,
        'Dashboard navigation': 0,
        'Create a measurement': 0,
        'Create a reduction target': 0,
        'Reduction action management': 0,
        'Share page': 0
      },
      currentIndex: 0,
      currentSection: 'App navigation',
      currentStep: 'intro dialog',
      onboardingComplete: false,
      setOnboardingComplete: (newState) =>
        set({ onboardingComplete: newState }),
      setCurrentIndex: (
        direction,
        currentIndex,
        currentSection,
        onboardingProgress,
        updateProgress
      ) => {
        if (direction === false) {
          // if (currentIndex === 0 && currentSection != 'Home') {
          //   const previousSectionIndex =
          //     onboardingSectionList.findIndex((x) => x === currentSection) - 1;
          //   const previousSectionList =
          //     onboardingMaster[onboardingSectionList[previousSectionIndex]];
          //   const previousSection = onboardingSectionList[previousSectionIndex];
          //   set({
          //     currentSection: onboardingSectionList[previousSectionIndex]
          //   });
          //   set({ currentSectionLength: previousSectionList.length });
          //   set({ currentIndex: previousSectionList.length - 1 });
          //   set({
          //     currentStep:
          //       previousSectionList[previousSectionList.length - 1].id
          //   });
          //   set({ active: false });
          //   if (updateProgress) {
          //     set({
          //       onboardingProgress: {
          //         ...onboardingProgress,
          //         [previousSection]: previousSectionList.length - 1
          //       }
          //     });
          //   }
          // } else
          if (currentIndex > 0) {
            set({ currentIndex: currentIndex - 1 });
            set({
              currentStep:
                onboardingMaster[currentSection][currentIndex - 1].id
            });
            if (updateProgress) {
              set({
                onboardingProgress: {
                  ...onboardingProgress,
                  [currentSection]: currentIndex - 1
                }
              });
            }
          }
        }
        if (direction === true) {
          if (
            currentIndex ===
              onboardingMaster[currentSection].length - 1 &&
            currentSection != 'Settings'
          ) {
            const nextSectionIndex =
              onboardingSectionList.findIndex(
                (x) => x === currentSection
              ) + 1;
            // const nextSectionList =
            //   onboardingMaster[onboardingSectionList[nextSectionIndex]];
            const nextSection =
              onboardingSectionList[nextSectionIndex];
            // set({ currentSection: onboardingSectionList[nextSectionIndex] });
            // set({ currentSectionLength: nextSectionList.length });
            // set({ currentIndex: 0 });
            // set({ currentStep: nextSectionList[0].id });
            if (updateProgress) {
              set({
                onboardingProgress: {
                  ...onboardingProgress,
                  [currentSection]: currentIndex + 1,
                  [nextSection]: 0
                }
              });
              set({ active: false });
            }
          } else if (
            currentIndex <
            onboardingMaster[currentSection].length - 1
          ) {
            set({ currentIndex: currentIndex + 1 });
            set({
              currentStep:
                onboardingMaster[currentSection][currentIndex + 1].id
            });
            if (updateProgress) {
              set({
                onboardingProgress: {
                  ...onboardingProgress,
                  [currentSection]: currentIndex + 1
                }
              });
            }
          } else if (
            /* Last step of the last section ends the onboarding flow */
            currentIndex ===
              onboardingMaster[currentSection].length - 1 &&
            currentSection === 'Settings'
          ) {
            set({ active: false });
            if (updateProgress) {
              set({
                onboardingProgress: {
                  ...onboardingProgress,
                  [currentSection]: currentIndex + 1
                }
              });
            }
          }
        }
      },
      setCurrentSection: (section) => {
        const newSection = onboardingMaster[section];
        set({ currentSection: section });
        set({ currentSectionLength: newSection.length });
        set({ currentIndex: 0 });
        set({ currentStep: newSection[0].id });
      }
    }),
    {
      name: 'onboarding-store'
    }
  )
);

// export function applyTooltip(
//   id: string,
//   component,
//   content: string,
//   action?: () => void,
//   condition?: boolean
// ) {
//   const { isOnboardingActive, currentStep } = useOnboarding(
//     (store: OnboardingType) => ({
//       isOnboardingActive: store.active,
//       currentStep: store.currentStep
//     })
//   );

//   console.log(isOnboardingActive, currentStep, id);
//   if (isOnboardingActive && currentStep === id) {
//     return (
//       <OnboardingTooltip
//         tooltipContent={content}
//         action={action}
//         condition={condition}
//       >
//         {component}
//       </OnboardingTooltip>
//     );
//   } else {
//     return <>{component}</>;
//   }
// }

export default useOnboarding;
